import React, { Component } from "react"
import '@rainbow-me/rainbowkit/dist/index.css'
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit"
import { chain, configureChains, createClient, WagmiConfig } from "wagmi"
import { alchemyProvider } from "wagmi/providers/alchemy"
import { publicProvider } from "wagmi/providers/public"
import { infuraProvider } from 'wagmi/providers/infura'



import routes from "../src/routes"
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom"

//import style
import "./assets/css/pe-icon-7.css"
import "./assets/scss/themes.scss"

const avalanche: Chain = {
  id: 43_114,
  name: 'Avalanche',
  network: 'avalanche',
  iconUrl: 'https://example.com/icon.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Avalanche',
    symbol: 'AVAX',
  },
  rpcUrls: {
    public: { http: ['https://api.avax.network/ext/bc/C/rpc'] },
    default: { http: ['https://api.avax.network/ext/bc/C/rpc'] },
  },
  blockExplorers: {
    default: { name: 'SnowTrace', url: 'https://snowtrace.io' },
    etherscan: { name: 'SnowTrace', url: 'https://snowtrace.io' },
  },
  testnet: false,
}

const { chains, provider } = configureChains(
  [avalanche],
  [infuraProvider({ apiKey: '977c6d8b9b254c798c80d6552785eaf7' }), publicProvider()]
)

const { connectors } = getDefaultWallets({
  appName: "RokkLaunch",
  chains
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} modalSize="compact" initialChain={chain.arbitrum}>
          <React.Fragment>
            <Router>
              <Switch>
                {routes.map((route, idx) => (
                  <Route path={route.path} component={route.component} key={idx} />
                ))}
              </Switch>
            </Router>
          </React.Fragment>
        </RainbowKitProvider>
      </WagmiConfig>
    )
  }
}

export default withRouter(App)
